.menu{
    position: absolute;
    z-index: 100;
}

.menuitem
{
    margin:0px;
    padding: 10px;
    border: 1px solid #bbb;
    cursor: pointer;
    width:400px;
    position: relative; 
    opacity:0.9;
}

.menuitem span
{   
    font-size: 1.2em;
    text-decoration: none;
    
}

.menuitem-focus{
    background-color: #f00;
    color:white;
    
}

.menuitem-unfocus{
    background-color: #333;
    color:#eec;
    transition: background-color 0.5s linear, color 0.5s linear;
}

.menuFiller{
    /*background-color: #f0f;*/
    height:50px;
}