body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

canvas{
display:block;
position: absolute;
z-index: 1;
}

.menu{
    position: absolute;
    z-index: 100;
}

.menuitem
{
    margin:0px;
    padding: 10px;
    border: 1px solid #bbb;
    cursor: pointer;
    width:400px;
    position: relative; 
    opacity:0.9;
}

.menuitem span
{   
    font-size: 1.2em;
    text-decoration: none;
    
}

.menuitem-focus{
    background-color: #f00;
    color:white;
    
}

.menuitem-unfocus{
    background-color: #333;
    color:#eec;
    -webkit-transition: background-color 0.5s linear, color 0.5s linear;
    transition: background-color 0.5s linear, color 0.5s linear;
}

.menuFiller{
    /*background-color: #f0f;*/
    height:50px;
}
